import { configConstants, status } from "../_constants";
import { configServices } from "../_services";
import { alert } from "../_utilities";

export const configActions = {
  loadConfigData,
};

function loadConfigData(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: configConstants.CONFIG_REQUEST,
        data: null,
      })
    );
    configServices
      .loadConfigData(data)
      .then(
        (response) => {
          if (response?.status === status.SUCCESS) {
            dispatch(
              dispatchFunction({
                type: configConstants.CONFIG_SUCCESS,
                data: response.data,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: configConstants.CONFIG_FAILURE,
                data: response,
              })
            );
            alert.success(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: configConstants.CONFIG_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}

function dispatchFunction(data) {
  return {
    type: data.type,
    data: data.data,
  };
}
