import { orderingListConstant, status } from "../_constants";

let initialState = {};

export function orderingList(state = initialState, action) {
  switch (action.type) {
    
    case orderingListConstant.SET_ORDERING_LIST_REQUEST:
      return {
        ...state,
        ordering_list_status: status.IN_PROGRESS,
        ordering_list_data: null,
      };
    case orderingListConstant.SET_ORDERING_LIST_SUCCESS:
      return {
        ...state,
        ordering_list_status: status.SUCCESS,
        ordering_list_data: action?.data,
      };
    case orderingListConstant.SET_ORDERING_LIST_FAILURE:
      return {
        ...state,
        ordering_list_status: status.FAILURE,
        ordering_list_data: action?.data,
      };


    case orderingListConstant.SET_PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        product_details_status: status.IN_PROGRESS,
        product_details_data: null,
      };
    case orderingListConstant.SET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        product_details_status: status.SUCCESS,
        product_details_data: action?.data,
      };
    case orderingListConstant.SET_PRODUCT_DETAILS_FAILURE:
      return {
        ...state,
        product_details_status: status.FAILURE,
        product_details_data: action?.data,
      };

    case orderingListConstant.GET_USER_ORDERING_LIST_REQUEST:
      return {
        ...state,
        user_ordering_list_status: status.IN_PROGRESS,
        user_ordering_list_data: null,
      };
    case orderingListConstant.GET_USER_ORDERING_LIST_SUCCESS:
      return {
        ...state,
        user_ordering_list_status: status.SUCCESS,
        user_ordering_list_data: action?.data,
      };
    case orderingListConstant.GET_USER_ORDERING_LIST_FAILURE:
      return {
        ...state,
        user_ordering_list_status: status.FAILURE,
        user_ordering_list_data: action?.data,
      };


    case orderingListConstant.GET_ORDER_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        order_details_status: status.IN_PROGRESS,
        order_details_data: null,
      };
    case orderingListConstant.GET_ORDER_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        order_details_status: status.SUCCESS,
        order_details_data: action?.data,
      };
    case orderingListConstant.GET_ORDER_DETAILS_BY_ID_FAILURE:
      return {
        ...state,
        order_details_status: status.FAILURE,
        order_details_data: action?.data,
      };

    case orderingListConstant.AVAILABLE_ORDER_TIMINGS_REQUEST:
      return {
        ...state,
        available_order_timings_status: status.IN_PROGRESS,
        available_order_timings_data: null,
      };
    case orderingListConstant.AVAILABLE_ORDER_TIMINGS_SUCCESS:
      return {
        ...state,
        available_order_timings_status: status.SUCCESS,
        available_order_timings_data: action?.data,
      };
    case orderingListConstant.AVAILABLE_ORDER_TIMINGS_FAILURE:
      return {
        ...state,
        available_order_timings_status: status.FAILURE,
        available_order_timings_data: action?.data,
      };
      
    case orderingListConstant.GET_STORE_REQUEST:
      return {  
        ...state,
        store_status: status.IN_PROGRESS,
        store_data: null,
      };
    case orderingListConstant.GET_STORE_SUCCESS:
      return {
        ...state,
        store_status: status.SUCCESS,
        store_data: action?.data,
      };
    case orderingListConstant.GET_STORE_FAILURE:
      return {
        ...state,
        store_status: status.FAILURE,
        store_data: action?.data,
      };
    
    case orderingListConstant.GET_STORE_BY_ID_REQUEST:
      return {
        ...state,
        store_by_id_status: status.IN_PROGRESS,
        store_by_id_data: null,
      };
    case orderingListConstant.GET_STORE_BY_ID_SUCCESS:
      return {
        ...state,
        store_by_id_status: status.SUCCESS,
        store_by_id_data: action?.data,
      };
    case orderingListConstant.GET_STORE_BY_ID_FAILURE:
      return {
        ...state,
        store_by_id_status: status.FAILURE,
        store_by_id_data: action?.data,
      };
      
    default:
      return state;
  }
}
