import { combineReducers } from "redux";
import { auth } from "./auth.reducers";
// import { authConstants } from "../_constants";
import { orderingList } from "./ordering.reducer";
import { cartList } from "./cart.reducers";
import { payment } from "./payment.reducers";
import { config } from "./config.reducers";
import { home } from "./homeContent.reducers"

const appReducers = combineReducers({
  auth,
  orderingList,
  cartList,
  payment,
  config,
  home
});

const rootReducer = (state, action) => {
  // if (action.type === authConstants.LOGOUT) {
  //     state = undefined;
  // }
  return appReducers(state, action);
};

export default rootReducer;
