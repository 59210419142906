import { orderingListConstant, status } from "../_constants";
import { orderingServices } from "../_services";
import { alert } from "../_utilities";

export const orderingActions = {
  getOrderingList,
  getProductDetails,
  getUserOrderList,
  getOrderDetails,
  availableDeliveryTiming,
  getStore,
  getStoreById,
};

function getOrderingList(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: orderingListConstant.SET_ORDERING_LIST_REQUEST,
        data: null,
      })
    );
    orderingServices
      .getOredringList(data)
      .then(
        (response) => {
          if (response?.status === status.SUCCESS) {
            dispatch(
              dispatchFunction({
                type: orderingListConstant.SET_ORDERING_LIST_SUCCESS,
                data: response.data,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: orderingListConstant.SET_ORDERING_LIST_FAILURE,
                data: response,
              })
            );
            alert.success(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: orderingListConstant.SET_ORDERING_LIST_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}

function getProductDetails(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: orderingListConstant.SET_PRODUCT_DETAILS_REQUEST,
        data: null,
      })
    );
    orderingServices
      .getProductDetails(data)
      .then(
        (response) => {
          if (response.status === status.SUCCESS) {
            dispatch(
              dispatchFunction({
                type: orderingListConstant.SET_PRODUCT_DETAILS_SUCCESS,
                data: response.data,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: orderingListConstant.SET_PRODUCT_DETAILS_FAILURE,
                data: response,
              })
            );
            alert.success(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: orderingListConstant.SET_PRODUCT_DETAILS_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}
function getUserOrderList(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: orderingListConstant.GET_USER_ORDERING_LIST_REQUEST,
        data: null,
      })
    );
    orderingServices
      .getUserOrderList(data)
      .then(
        (response) => {
          if (response.status === status.SUCCESS) {
            dispatch(
              dispatchFunction({
                type: orderingListConstant.GET_USER_ORDERING_LIST_SUCCESS,
                data: response.data,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: orderingListConstant.GET_USER_ORDERING_LIST_FAILURE,
                data: response,
              })
            );
            alert.success(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: orderingListConstant.GET_USER_ORDERING_LIST_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}
function getOrderDetails(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: orderingListConstant.GET_ORDER_DETAILS_BY_ID_REQUEST,
        data: null,
      })
    );
    orderingServices
      .getOrderDetails(data)
      .then(
        (response) => {
          if (response.status === status.SUCCESS) {
            dispatch(
              dispatchFunction({
                type: orderingListConstant.GET_ORDER_DETAILS_BY_ID_SUCCESS,
                data: response.data,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: orderingListConstant.GET_ORDER_DETAILS_BY_ID_FAILURE,
                data: response,
              })
            );
            alert.success(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: orderingListConstant.GET_ORDER_DETAILS_BY_ID_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}
function availableDeliveryTiming(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: orderingListConstant.AVAILABLE_ORDER_TIMINGS_REQUEST,
        data: null,
      })
    );
    orderingServices
      .availableDeliveryTiming(data)
      .then(
        (response) => {
          if (response.status === status.SUCCESS) {
            dispatch(
              dispatchFunction({
                type: orderingListConstant.AVAILABLE_ORDER_TIMINGS_SUCCESS,
                data: response.data,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: orderingListConstant.AVAILABLE_ORDER_TIMINGS_FAILURE,
                data: response,
              })
            );
            alert.success(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: orderingListConstant.AVAILABLE_ORDER_TIMINGS_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}
function getStore(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: orderingListConstant.GET_STORE_REQUEST,
        data: null,
      })
    );
    orderingServices
      .getStore(data)
      .then(
        (response) => {
          if (response.status === status.SUCCESS) {
            dispatch(
              dispatchFunction({
                type: orderingListConstant.GET_STORE_SUCCESS,
                data: response.data,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: orderingListConstant.GET_STORE_FAILURE,
                data: response,
              })
            );
            alert.success(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: orderingListConstant.GET_STORE_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}
function getStoreById(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: orderingListConstant.GET_STORE_BY_ID_REQUEST,
        data: null,
      })
    );
    orderingServices
      .getStoreById(data)
      .then(
        (response) => {
          if (response.status === status.SUCCESS) {
            dispatch(
              dispatchFunction({
                type: orderingListConstant.GET_STORE_BY_ID_SUCCESS,
                data: response.data,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: orderingListConstant.GET_STORE_BY_ID_FAILURE,
                data: response,
              })
            );
            alert.success(response.message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: orderingListConstant.GET_STORE_BY_ID_FAILURE,
              data: error.message,
            })
          );
          alert.error("Oops! Something went wrong.");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong.");
      });
  };
}

function dispatchFunction(data) {
  return {
    type: data.type,
    data: data.data,
  };
}
