import React, { useState } from "react";
import FooterLogo from "../assets/images/header-logo.svg";
import heart from "../assets/images/heart.svg";
import footerlogo from "../assets/images/footer-logo.svg";
import callicon from "../assets/images/call-icon.svg";
import facebook from "../assets/images/facebook.svg";
import instagram from "../assets/images/instagram.svg";
import Snapchat from "../assets/images/Snapchat.svg";
import iconcalendar from "../assets/images/icon-calendar.svg";
import ordericon from "../assets/images/order_icon.svg";
import tiktokblack from "../assets/images/tiktok-black.svg";
import xcode from "../assets/images/xcode.svg";
import { config } from "../config";
import { useLocation } from "react-router";
// import iconloction from "../../assets/images/icon-location.svg";
import iconloction from "../assets/images/icon-location.svg";

function Footer() {
  const [configData, setConfigData] = useState(JSON.parse(localStorage.getItem("configData")));
  const location = useLocation();
  const hiddenFooter = ["/checkout", "/payment", "/cart"];

  if (hiddenFooter.includes(location.pathname))
    return null;

  const home = localStorage.getItem("home")
    ? JSON.parse(localStorage.getItem("home"))
    : null;

  return (
    <div className="footer" style={{ marginBottom: location.pathname === "/" ? "97px" : 0 }}>
      {location.pathname !== '/' && (
        <div className="footer-top">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-3">
                <div className="footer-logo">
                  <a href="/"><img src={configData ? `${config.stagingConfig}/tenancy/assets/${configData.headerLogo}` : FooterLogo} alt="" /></a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-text">

                  <ul>
                    <li>
                      <a href="tel:0161 620 2150">
                        <img src={callicon} />
                        {configData.contactNumber}
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <img src="/static/media/location-icon.74e74abcb3dc5bf28e3a15911d5e6e19.svg" />
                        123 Main Street, London, L1 2LE
                      </a>
                    </li>
                    <p>{home && home.locationData.addressLine1} {home && home.locationData.addressLine2} </p>

                    {/* <div className="get-btn">
                      <a
                        href={home && home.locationData.directionLink}
                        target="_blank"
                      >
                        <div className="icon-location" />
                        <span>Get Directions</span>
                      </a>
                    </div> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="footer-icon">
                  <ul>
                    <li><a href="https://www.tiktok.com/burgerking" target="_blank"><img src={tiktokblack} alt="" /></a></li>
                    <li><a href="https://www.instagram.com/burgerking" target="_blank"><img src={instagram} alt="" /></a></li>
                    <li><a href="https://www.facebook.com/burgerking" target="_blank"><img src={facebook} alt="" /></a></li>
                    <li><a href="https://www.Snapchat.com/burgerking" target="_blank"><img src={Snapchat} alt="" /></a></li>
                    <li><a href="https://www.twitter.com" target="_blank"><img src={xcode} alt="" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="footer-bottom">
        <div className="container">
          <div className="copy-right-inner">
            <em>
              ©
              <p className="copy-right-text">2024. {configData && configData.websiteName}. All rights reserved.</p>
            </em>
            {location.pathname !== '/' && (
              <ul>
                <li>
                  <a href="/">Terms of Service</a>
                </li>
                <li>
                  <a href="/">Privacy</a>
                </li>
              </ul>
            )}
            <p className="loyalty-text">made with
              <em>
                <img src={heart} alt="heart icon" />
              </em>
              by
              <a href="/">
                <img src={footerlogo} alt="" />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;


