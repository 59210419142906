import { cartConstant, status } from "../_constants";
import { alert } from "../_utilities";

export const cartActions = {
  updateCart,
  clearCart,
};
const initialState = {};
function updateCart(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: cartConstant.SET_CART_ITEMS_REQUEST,
        data: null,
      })
    );

    if (data) {
      dispatch(
        dispatchFunction({
          type: cartConstant.SET_CART_ITEMS_SUCCESS,
          data: data.cartData,
        })
      );
      alert.success(data.message);
    } else {
      dispatch(
        dispatchFunction({
          type: cartConstant.SET_CART_ITEMS_FAILURE,
          data: data,
        })
      );
      alert.success("!Opps something went wrong");
    }
  };
}
function clearCart() {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: cartConstant.SET_CLEAR_CART_REQUEST,
        data: initialState,
      })
    );
    dispatch(
      dispatchFunction({
        type: cartConstant.SET_CLEAR_CART_SUCCESS,
        data: initialState,
      })
    );
  };
}

function dispatchFunction(data) {
  return {
    type: data.type,
    data: data.data,
  };
}
