export const status = {
    IN_PROGRESS: 0,
    SUCCESS: 200,
    FAILURE: 2
};

export const testStatus = {
    PENDING: 0,
    IN_PROGRESS: 1,
    COMPLETED: 2
};

export const componentType = {
    DESCRIPTION: "desc",
    MULTIPLE_CHOICE: "multiChoice",
    FREE_TEXT: "freeTxt",
    SINGLE_CHOICE: "singleChoice",
    OPINION: "opinion",
    RATE: "rating",
    GRID: "grid",
    LINESCALE: "lineScale",
    HEDONICSCALE: "hedonicScale",
    JAR: "JAR"
};