import { store } from "./../_store";
export const commonFunctions = {
  getRequestOptions,
  validateEmail,
  shareSocialPage,
  getFileName,
  convertDateToString,
  validateConatctNumber,
  getIframString,
  getMethodForCurrency,
  setMethodForCurrency,
};

function validateConatctNumber(number) {
  var re = /^[6-9]{1}[0-9]{9}$/;

  return re.test(String(number));
}

function getRequestOptions(type, extraHeaders, body, bNoToken) {
  let authHeader = {};
  let userData;
  // if (!bNoToken) {
  //     const currentState = store.getState();
  //     const userInfo = currentState.auth.user;
  //     const accessToken = userInfo ? userInfo.token : null;
  //     authHeader = {
  //         Authorization: `Bearer ${accessToken}`
  //     }
  // }
  if (bNoToken) {
    userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
    authHeader = {
      Authorization: `Bearer ${userData?.token}`,
    };
  }

  let requestOptions = {
    method: type,
    headers: {
      ...extraHeaders,
      ...authHeader,
    },
  };
  if (body) {
    requestOptions["body"] = body;
  }
  return requestOptions;
}

function getFileName(header, type) {
  let fileName = "downloaded." + type;
  if (header) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(header);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, "");
    }
  }
  return fileName;
}

function validateEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function shareSocialPage(url) {
  window.open(url, "", "width=600,height=600");
  return false;
}
function convertDateToString(dateObj) {
  if (dateObj && !isNaN(dateObj)) {
    let month = (dateObj.getMonth() + 1).toString();
    month = month.length === 1 ? "0" + month : month;
    let date = dateObj.getDate().toString();
    date = date.length === 1 ? "0" + date : date;
    let year = dateObj.getFullYear().toString();
    return `${year}-${month}-${date}`;
  }
  return "";
}

function getIframString(str) {
  let updatedStr = str.includes("iframe")
    ? str
        .replaceAll(/&lt;iframe/g, "<iframe")
        .replaceAll(/&gt;&lt;\/iframe&gt;/g, "></iframe>")
    : str;
  return updatedStr;
}

function getMethodForCurrency() {
  let obj = localStorage.getItem("currencyObj");
  return obj ? JSON.parse(obj) : { value: "GBP", label: "GBP", sign: "£" };
}

function setMethodForCurrency(obj) {
  localStorage.setItem("currencyObj", JSON.stringify(obj));
}
