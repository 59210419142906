import React, { useState, useRef, useEffect } from "react";
import resenticon from "../../assets/images/resent-icon.svg";
import arrowwhite from "../../assets/images/arrow-white.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../_actions";
import { status } from "../../_constants";
import CustomLoader from "../../components/CustomeLoader";

function Verification() {
  const [otpDigits, setOtpDigits] = useState(["", "", "", ""]);
  const otpInputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.auth);
  const OTP = useSelector((state) => state.auth);
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [Loader, setLoader] = useState(false);

  useEffect(() => {
    if (otpInputRefs[0].current) {
      otpInputRefs[0].current.focus();
    }
  }, []);



  useEffect(() => {
    if (loginData.user_login_status === status.SUCCESS) {
      localStorage.removeItem("user");
      setLoader(false);
      localStorage.setItem("userData", JSON.stringify(loginData?.user?.data));
      navigate("/menu");
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [loginData.user]);

  useEffect(() => {
    if (OTP.resend_otp_status === status.SUCCESS) {
      startTimer();
      setOtpDigits(["", "", "", ""]);
    } else {
      startTimer();
    }
  }, [OTP.resend_otp_res]);

  const resendOTP = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const contactNumberRegex = /^[1-9]\d{9,14}$|^\d{10,12}$/;
    if (emailRegex.test(localStorage.getItem("user"))) {
      dispatch(authActions.resendOtp({ email: localStorage.getItem("user") }));
    } else if (contactNumberRegex.test(localStorage.getItem("user"))) {
      dispatch(authActions.resendOtp({ phone: localStorage.getItem("user") }));
    }
  };

  const verifyToken = async () => {
    if (isAllTextInputCompleted()) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const contactNumberRegex = /^[1-9]\d{9,14}$|^\d{10,12}$/;
      const token = otpDigits.join("");
      if (token && emailRegex.test(localStorage.getItem("user"))) {
        dispatch(
          authActions.login({ otp: token, email: localStorage.getItem("user") })
        );
        setLoader(true);
      } else if (
        token &&
        contactNumberRegex.test(localStorage.getItem("user"))
      ) {
        dispatch(
          authActions.login({ otp: token, phone: localStorage.getItem("user") })
        );
        setLoader(true);
      }
    }
  };

  const isAllTextInputCompleted = () => {
    return !otpDigits.includes("");
  };

  const handleOtpInputChange = (index, value) => {
    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = value;
    setOtpDigits(newOtpDigits);
    // Move focus to the next input
    if (index < otpInputRefs.length - 1 && value !== "") {
      if (otpInputRefs[index + 1].current) {
        otpInputRefs[index + 1].current.focus();
      }
    }
    if (index === 5 && value !== "") {
      // Keyboard.dismiss();
    }
  };

  const handleOtpInputDelete = (index) => {
    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = "";
    setOtpDigits(newOtpDigits);
    // Move focus to the previous input
    if (index > 0 && otpInputRefs[index - 1].current) {
      otpInputRefs[index - 1].current.focus();
    }
  };
  const startTimer = () => {
    setSeconds(60);
    setIsRunning(true);
  };

  useEffect(() => {
    let timer;
    if (isRunning && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsRunning(false);
    }
    return () => clearInterval(timer);
  }, [isRunning, seconds]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      verifyToken(e);
    };
  }


  return (
    <div className="verification-page">
      {Loader ? (
        <CustomLoader />
      ) : (
        <div className="container">
          <div className="text-content">
            <h2>Enter the 4-digit code sent to you at:</h2>
            <strong>{localStorage.getItem("user")}</strong>
          </div>
          <div className="verification-code">
            <div className="textBoxs">
              {otpDigits.map((digit, index) => (
                <input
                  key={index}
                  type="tel"
                  ref={otpInputRefs[index]}
                  className={
                    isAllTextInputCompleted()
                      ? "inputViewCompleted"
                      : "inputView"
                  }
                  value={digit}
                  onChange={(e) => handleOtpInputChange(index, e.target.value)}
                  onKeyDown={handleKeyDown}
                  onKeyUp={({ nativeEvent }) => {
                    if (nativeEvent.key === "Backspace") {
                      handleOtpInputDelete(index);
                    }
                  }}
                  keyboardType="numeric"
                  maxLength={1}
                />
              ))}
            </div>
          </div>
          <p className="text-head">
            <span>Tip:</span> Make sure you check your inbox and spam folders
          </p>
          <div className="resent-btn">
            <button
              title="Continue"
              className="primary-btn sm Continue"
              onClick={() => {
                verifyToken();
              }}
            >
              Continue
              <span>
                <img src={arrowwhite} alt="" />
              </span>
            </button>
          </div>
          <div className="group-btn">
            <a
              href="/login"
              onClick={() => {
                localStorage.getItem("user");
              }}
            >
              <button title="Back" className="primary-btn sm Back">
                <span>
                  <img src={arrowwhite} alt="" />
                </span>
                Back
              </button>
            </a>
            <button
              title="Resend"
              className="primary-btn sm Continue resent"
              onClick={() => {
                resendOTP();
              }}
              disabled={isRunning}
            >
              {isRunning ? `Resend OTP in ${seconds} seconds` : "Resend"}

              {!isRunning && <span>
                <img src={resenticon} alt="" />
              </span>}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
export default Verification;
