export const orderingListConstant = {
  SET_ORDERING_LIST_REQUEST: "SET_ORDERING_LIST_REQUEST",
  SET_ORDERING_LIST_SUCCESS: "SET_ORDERING_LIST_SUCCESS",
  SET_ORDERING_LIST_FAILURE: "SET_ORDERING_LIST_FAILURE",

  SET_PRODUCT_DETAILS_REQUEST: "SET_PRODUCT_DETAILS_REQUEST",
  SET_PRODUCT_DETAILS_SUCCESS: "SET_PRODUCT_DETAILS_SUCCESS",
  SET_PRODUCT_DETAILS_FAILURE: "SET_PRODUCT_DETAILS_FAILURE",
  
  GET_USER_ORDERING_LIST_REQUEST: "GET_USER_ORDERING_LIST_REQUEST",
  GET_USER_ORDERING_LIST_SUCCESS: "GET_USER_ORDERING_LIST_SUCCESS",
  GET_USER_ORDERING_LIST_FAILURE: "GET_USER_ORDERING_LIST_FAILURE",

  GET_ORDER_DETAILS_BY_ID_REQUEST: "GET_ORDER_DETAILS_BY_ID_REQUEST",
  GET_ORDER_DETAILS_BY_ID_SUCCESS: "GET_ORDER_DETAILS_BY_ID_SUCCESS",
  GET_ORDER_DETAILS_BY_ID_FAILURE: "GET_ORDER_DETAILS_BY_ID_FAILURE",

  AVAILABLE_ORDER_TIMINGS_REQUEST: "AVAILABLE_ORDER_TIMINGS_REQUEST",
  AVAILABLE_ORDER_TIMINGS_SUCCESS: "AVAILABLE_ORDER_TIMINGS_SUCCESS",
  AVAILABLE_ORDER_TIMINGS_FAILURE: "AVAILABLE_ORDER_TIMINGS_FAILURE",
  
  GET_STORE_REQUEST:"GET_STORE_REQUEST",
  GET_STORE_SUCCESS:"GET_STORE_SUCCESS",
  GET_STORE_FAILURE:"GET_STORE_FAILURE",

  GET_STORE_BY_ID_REQUEST:"GET_STORE_BY_ID_REQUEST",
  GET_STORE_BY_ID_SUCCESS:"GET_STORE_BY_ID_SUCCESS",
  GET_STORE_BY_ID_FAILURE:"GET_STORE_BY_ID_FAILURE",
};
