import { config } from "./../config";
import { commonFunctions } from "../_utilities";

export const paymentServices = {
  getPaymentIntent,
  updatePaymentStatus
};

function getPaymentIntent(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "post",
    extraHeaders,
    JSON.stringify(data),
    true
  );
  return fetch(`${config.localApiUrl}/order`, requestOptions).then((response) =>
    response.json()
  );
}

function updatePaymentStatus(data){
  console.log(data)
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "put",
    extraHeaders,
    JSON.stringify(data),
    true
  );
  return fetch(`${config.localApiUrl}/order/payment/status`, requestOptions).then((response) =>
    response.json()
  );
}
