import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderLogo from "../assets/images/header-logo.svg";
import footerlogo from "../assets/images/footer-logo.svg";
import heart from "../assets/images/heart.svg";
// import applepay from "../assets/images/apple-pay.svg";
import usericon from "../assets/images/user-icon.svg";
import ordericon from "../assets/images/order-icon.svg";
import promotionicon from "../assets/images/promotion-icon.svg";
import giftmenuicon from "../assets/images/gift-menu-icon.svg";
import arrowwhite from "../assets/images/arrow-white.svg";
import carticon from "../assets/images/cart_icon.svg";
import { config } from "../config";
import { useDispatch, useSelector } from "react-redux";
import { configActions } from "../_actions";
import { status } from "../_constants";
import CustomLoader from "./CustomeLoader";
import { alert } from "../_utilities";
function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cartList.cart_list_data);
  const conData = useSelector((state) => state.config);
  let userData = localStorage.getItem("userData");

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const [configData, setConfigData] = useState();

  useEffect(() => {
    dispatch(configActions.loadConfigData());
    setIsLoader(true);
  }, []);

  useEffect(() => {
    if (conData && conData?.config_status == status?.SUCCESS) {
      setConfigData(conData?.config_data);
      localStorage.setItem("configData", JSON.stringify(conData?.config_data));
      setIsLoader(false);
    }
  }, [conData?.config_data]);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  // const redirectTo = (page) => {
  //   if (page && page === "signup") {
  //     setIsMenuOpen(false);
  //     navigate("/");
  //   } else if (page && page === "login") {
  //     setIsMenuOpen(false);
  //     navigate("/login");
  //   }
  // };

  return (
    <header className="header-area">
      {isLoader ? (
        <CustomLoader />
      ) : (
        <div className="container">
          <div className="row flex-wrap-nowrap">
            <div className="col-lg-6 col-6">
              <div className="header-left">
                <Link to="/">
                  <img
                    src={
                      configData
                        ? `${config.stagingConfig}/tenancy/assets/${configData.headerLogo}`
                        : HeaderLogo
                    }
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="header-right">
                <div className="cart-icon">
                  {
                    <Link
                      onClick={() =>
                        cartData &&
                        cartData.cartData &&
                        cartData.cartData.length <= 0 &&
                        alert.error("Please add items to cart first.!")
                      }
                      to={
                        cartData &&
                        cartData.cartData &&
                        cartData.cartData.length > 0
                          ? "/cart"
                          : "/menu"
                      }
                    >
                      <img src={carticon} alt="" />
                      {cartData &&
                        cartData.cartData &&
                        cartData.cartData.length > 0 && (
                          <span>{cartData.cartData.length}</span>
                        )}
                    </Link>
                  }
                </div>
                <button
                  className={`menu-button ${isOpen == true ? "open" : ""}`}
                  onClick={ToggleSidebar}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <nav className={`menu ${isOpen == true ? "open" : ""}`}>
                  <div className="sd-header">
                    <div className="menu-close" onClick={ToggleSidebar}>
                      <img src={arrowwhite} alt="" />
                    </div>
                  </div>
                  {userData == null ? (
                    <div className="hide-desk">
                      <div className="logo">
                        <img src={HeaderLogo} alt="" />
                      </div>
                      <div className="signup-btn">
                        <a href="/login">
                          <button
                            title="Sign Up"
                            className="btn-primary sm Sign-btn"
                            // onClick={() => redirectTo("signup")}
                          >
                            Sign Up
                          </button>
                        </a>
                        <a href="/login">
                          <button
                            title="Log in"
                            className="btn-primary sm login-btn"
                            // onClick={() => redirectTo("login")}
                          >
                            Log in
                          </button>
                        </a>
                      </div>
                      <div className="menu-bottom-inner">
                        <div className="menu-bottom">
                          <div className="bottom-logo">
                            <img src={HeaderLogo} alt="" />
                          </div>
                          <div className="content">
                            <p>There’s more to love in the app.</p>
                            <button
                              title="Get the app"
                              className="btn-primary sm Get-the"
                            >
                              <div className="apple_pay" />
                              Get the app
                            </button>
                          </div>
                        </div>
                        <p className="loyalty-text">
                          made with
                          <em>
                            <img src={heart} alt="heart icon" />
                          </em>
                          by
                          <a href="https://sumoeats.co.uk/">
                            <img src={footerlogo} alt="" />
                          </a>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="hide-desk">
                      <div className="logo">
                        <img src={usericon} alt="" />
                        <h2>{userData && userData?.customer?.first_name}</h2>
                        <p
                          onClick={() => {
                            setIsopen(false);
                            navigate("/account");
                          }}
                        >
                          Manage account
                        </p>
                      </div>
                      <div className="meno-menu">
                        <ul className="mobo-menu">
                          <li>
                            <Link
                              to="/orders"
                              onClick={() => {
                                setIsopen(false);
                              }}
                            >
                              <img src={ordericon} alt="" />
                              Orders
                            </Link>
                          </li>
                          <li>
                            <a href="#">
                              <img src={promotionicon} alt="" />
                              Promotions
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <img src={giftmenuicon} alt="" />
                              Invite Friends <p>You get £5 off</p>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="signup-btn">
                        <button
                          title="Sign Out"
                          className="btn-primary sm Sign-out-btn"
                          onClick={() => {
                            localStorage.removeItem("userData");
                            setIsopen(false);
                            navigate("/");
                          }}
                        >
                          Sign Out
                        </button>
                      </div>
                      <div className="menu-bottom-inner">
                        <div className="menu-bottom">
                          <div className="bottom-logo">
                            <img src={HeaderLogo} alt="" />
                          </div>
                          <div className="content">
                            <p>There’s more to love in the app.</p>
                            <button
                              title="Get the app"
                              className="btn-primary sm Get-the"
                            >
                              <div className="apple_pay" />
                              Get the app
                            </button>
                          </div>
                        </div>
                        <p className="loyalty-text">
                          made with
                          <em>
                            <img src={heart} alt="heart icon" />
                          </em>
                          by
                          <a href="https://sumoeats.co.uk/">
                            <img src={footerlogo} alt="" />
                          </a>
                        </p>
                      </div>
                    </div>
                  )}
                </nav>
              </div>
              <div
                className={`sidebar-overlay ${isOpen == true ? "active" : ""}`}
                onClick={ToggleSidebar}
              ></div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
