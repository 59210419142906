import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.css";
// import { ParentDomainList } from "./config";
// import { ParentSiteLayout } from "./parent/Layout";
import { ChildThemeLayout } from "./tenant/Layout";
import { ToastContainer } from "react-toastify";
import "./tenant/assets/style/style.scss";
import CustomLoader from "./tenant/components/CustomeLoader";
import { config } from "./tenant/config";
// import { Provider } from "react-redux";
// import { store } from "./_store";
// import CustomLoader from "./utils/commonFunctions/commonLoader";

function App() {
  // const navigate = useNavigate();

  const createRoutes = () => {
    // console.log(window.location.origin,'edit');
    // if (ParentDomainList.includes(window.location.origin)) {
    //   return <Route path="/" element={<ParentSiteLayout />} />;
    // } else {
    return <Route path="/*" element={<ChildThemeLayout />} />;
    // }
  };

  return (
    // <Provider store={store}>
    <div className="App">
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Suspense fallback={<CustomLoader />}>
        <ToastContainer />
        <Routes>{createRoutes()}</Routes>
      </Suspense>
    </div>
    // </Provider>
  );
}

export default App;
