import React from "react";
import { Bars } from "react-loader-spinner";

function CustomLoader() {
  return (
    <div className="custom-loader">
      <Bars
        height="80"
        width="80"
        // color="#960012"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="bars-loadings"
        visible={true}
      />
    </div>
  );
}

export default CustomLoader;
