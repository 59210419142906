import { cartConstant, status } from "../_constants";

let initialState = {};

export function cartList(state = initialState, action) {
  switch (action.type) {
    case cartConstant.SET_CART_ITEMS_REQUEST:
      return {
        ...state,
        cart_status: status.IN_PROGRESS,
        cart_list_data: null,
      };
    case cartConstant.SET_CART_ITEMS_SUCCESS:
      return {
        ...state,
        cart_status: status.SUCCESS,
        cart_list_data: action?.data,
      };
    case cartConstant.SET_CART_ITEMS_FAILURE:
      return {
        ...state,
        cart_status: status.FAILURE,
        cart_list_data: action?.data,
      };
    case cartConstant.SET_CLEAR_CART_REQUEST:
      return {
        ...state,
        cart_status: status.IN_PROGRESS,
        cart_list_data: null,
      };
    case cartConstant.SET_CLEAR_CART_SUCCESS:
      return {
        ...state,
        cart_status: status.SUCCESS,
        cart_list_data: initialState,
      };
    case cartConstant.SET_CLEAR_CART_FAILURE:
      return {
        ...state,
        cart_status: status.FAILURE,
        cart_list_data: {},
      };
    default:
      return state;
  }
}
