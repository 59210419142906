import { config } from "./../config";
import { commonFunctions } from "../_utilities";

export const configServices = {
  loadConfigData,
};

function loadConfigData() {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "post",
    extraHeaders,
  );
  return fetch(`${config.localApiUrl}/loadconfig`, requestOptions).then((response) =>
    response.json()
  );
}
