import { paymentConstant, status } from "../_constants";

let initialState = {};

export function payment(state = initialState, action) {
  switch (action.type) {
    case paymentConstant.SET_PAYMENT_INT_REQUEST:
      return {
        ...state,
        payment_int_status: status.IN_PROGRESS,
        payment_int_data: null,
      };
    case paymentConstant.SET_PAYMENT_INT_SUCCESS:
      return {
        ...state,
        payment_int_status: status.SUCCESS,
        payment_int_data: action?.data,
      };
    case paymentConstant.SET_PAYMENT_INT_FAILURE:
      return {
        ...state,
        payment_int_status: status.FAILURE,
        payment_int_data: action?.data,
      };

    default:
      return state;
  }
}
