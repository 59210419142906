// import { homeConstants, status } from "../_constants";
import { status } from "../_constants";
import { homeConstants } from "../_constants/homeContent.constant";

let initialState = [];

export function home(state = initialState, action) {
    switch (action.type) {
        case homeConstants.LOAD_HOME_DATA_REQUEST:
            return {
                ...state,
                homeContent_status: status.IN_PROGRESS,
                homeContent_data: null,
            };
        case homeConstants.LOAD_HOME_DATA_SUCCESS:
            return {
                ...state,
                homeContent_status: status.SUCCESS,
                homeContent_data: action?.data,
            };
        case homeConstants.LOAD_HOME_DATA_FAILURE:
            return {
                ...state,
                homeContent_status: status.FAILURE,
                homeContent_data: action?.data,
            };

        default:
            return state;
    }
}
